import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ProtocoloContainer } from './Protocolo.styles';
import QuestionIcon from '../../Components/Images/Protocolo/question-icon.svg';
import { useAuth } from '../../AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Protocolo() {
    const navigate = useNavigate();
    const location = useLocation();
    const codFarmacia = location.state?.codFarmacia;
    const protocolId = location.state?.protocolId;
    const [protocolData, setProtocolData] = useState(null);
    const [productData, setProductData] = useState([]);
    const [protocolNumber, setProtocolNumber] = useState('');
    const [visibleFields, setVisibleFields] = useState({});
    const [formValues, setFormValues] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { currentStep, setCurrentStep } = useAuth();
    const [serialValidationErrors, setSerialValidationErrors] = useState({});
    const [isSerialUnique, setIsSerialUnique] = useState(true);

    useEffect(() => {
        if (currentStep < 1) {
            navigate('/informacoes');
        } else {
            setCurrentStep(2);
        }
    }, [currentStep, setCurrentStep, navigate]);

    useEffect(() => {
        const storedJsonOutput = localStorage.getItem('jsonOutput');
        if (storedJsonOutput) {
            const parsedJsonOutput = JSON.parse(storedJsonOutput);
            setProtocolData(parsedJsonOutput);
            setProductData(parsedJsonOutput.itens_troca);
            setProtocolNumber(parsedJsonOutput.numero_protocolo);

            // Inicializa os valores do formulário e os campos visíveis
            const initialFormValues = {};
            const initialVisibleFields = {};
            parsedJsonOutput.itens_troca.forEach(item => {
                initialFormValues[`${item.cod_item}-serie`] = item.numero_serie;
                initialFormValues[`${item.cod_item}-lote`] = item.lote;
                initialFormValues[`${item.cod_item}-validade`] = item.validade;

                // Define os campos como visíveis se algum valor estiver preenchido
                const isAnyFieldFilled = item.numero_serie || item.lote || item.validade;
                initialVisibleFields[item.cod_item] = isAnyFieldFilled;
            });
            setFormValues(initialFormValues);
            setVisibleFields(initialVisibleFields);
        } else if (protocolId) {
            axios.get(`${API_BASE_URL}/api/protocol/id/${protocolId}`, { withCredentials: true })
                .then(response => {
                    setProtocolData(response.data);
                    setProductData(response.data.produtos.filter(product => product.status === "Aberto"));
                    setProtocolNumber(response.data.numero_protocolo);
                })
                .catch(error => {
                    console.error('Error ao buscar o número de caso:', error);
                });
        }
    }, [protocolId]);

    const handleFillCadastro = (productId) => {
        setVisibleFields((prevState) => ({ ...prevState, [productId]: true }));
    };

    const handleCancel = (productId) => {
        setVisibleFields((prevState) => ({ ...prevState, [productId]: false }));
        setFormValues((prevState) => {
            const updatedValues = { ...prevState };
            delete updatedValues[`${productId}-serie`];
            delete updatedValues[`${productId}-lote`];
            delete updatedValues[`${productId}-validade`];
            return updatedValues;
        });
    };

    const handleInputChange = async (event, productId, field) => {
        let { value } = event.target;
        const product = productData.find(p => p.cod_item === productId);
    
        let isValid = true;
        let errorMessage = '';
    
        if (field === 'serie') {
            // Permitir letras, números e hífens apenas para leitores
            const regexAlfanumerico = product.tipo_dispositivo === 'leitor' ? /^[a-zA-Z0-9-]+$/ : /^[a-zA-Z0-9]+$/;
            isValid = regexAlfanumerico.test(value);
            if (!isValid) errorMessage = product.tipo_dispositivo === 'leitor' 
                ? 'El número de serie solo puede contener letras, números y guiones.' 
                : 'El número de serie solo puede contener letras y números.';
        }
    
        if (field === 'lote') {
            if (product.tipo_dispositivo === 'sensor') {
                const regexSensor = /^[A-Za-z]{3}\d{6}$/;
                isValid = regexSensor.test(value);
                if (!isValid) errorMessage = 'El lote del sensor debe contener 3 letras seguidas de 6 números.';
            } else if (product.tipo_dispositivo === 'leitor') {
                isValid = value.length === 7;
                if (!isValid) errorMessage = 'El lote del lector debe contener exactamente 7 caracteres.';
            }
        }
    
        if (field === 'validade') {
            isValid = !!value.trim();
            if (!isValid) errorMessage = 'La fecha de vencimiento es obligatoria.';
        }
    
        setFormValues(prevState => ({
            ...prevState,
            [`${productId}-${field}`]: value
        }));
    
        setSerialValidationErrors(prevErrors => ({
            ...prevErrors,
            [`${productId}-${field}`]: isValid ? '' : errorMessage
        }));
    
        if (field === 'serie' && isValid) {
            validateSerialNumbers({ ...formValues, [`${productId}-serie`]: value }, productId, value);
        }
    };

    // Função separada para validar os números de série
    const validateSerialNumbers = async (updatedValues, currentProductId, currentSerial) => {
        try {
            // Verifica duplicatas na tela
            const enteredSerials = Object.entries(updatedValues)
                .filter(([key, val]) => key.endsWith('-serie') && val === currentSerial)
                .map(([key]) => key.split('-')[0]); // Pega o productId do campo

            const isDuplicateInScreen = enteredSerials.length > 1;

            // Verifica se o número de série já existe no banco de dados
            const response = await axios.get(`${API_BASE_URL}/api/serialNumber/${currentSerial}`, { withCredentials: true });
            const serialExists = response.data.SerialNumber.length > 0;

            setSerialValidationErrors((prevErrors) => ({
                ...prevErrors,
                [currentProductId]: serialExists
                    ? 'Número de serie ya registrado en el sistema'
                    : isDuplicateInScreen
                        ? 'Número de serie ya ingresado en este protocolo'
                        : '',
            }));

            setIsSerialUnique(!serialExists && !isDuplicateInScreen);
        } catch (error) {
            console.error('Error al validar el número de serie:', error);
        }
    };

    useEffect(() => {
        const hasVisibleFields = Object.values(visibleFields).some(value => value); // Verifica se pelo menos um produto tem o formulário aberto
    
        const isValid = hasVisibleFields && productData.every(product => {
            if (!visibleFields[product.cod_item]) {
                return true; // Se o formulário não foi aberto para esse produto, ele não impede o avanço.
            }
    
            const type = product.tipo_dispositivo;
            const serieField = type !== 'strip' ? formValues[`${product.cod_item}-serie`] : true;
            const loteField = formValues[`${product.cod_item}-lote`];
            const validadeField = type !== 'leitor' ? formValues[`${product.cod_item}-validade`] : true;
    
            return !!(serieField && loteField && validadeField);
        });
    
        // O botão só será ativado se pelo menos um formulário estiver aberto, todos os campos obrigatórios forem preenchidos e não houver erros
        const hasErrors = Object.values(serialValidationErrors).some(err => err);
        setIsFormValid(hasVisibleFields && isValid && !hasErrors);
    }, [formValues, productData, visibleFields, serialValidationErrors]);
    
    const handleAvancar = () => {
        if (isFormValid) {
            const jsonOutput = {
                cod_protocolo: protocolData.cod_protocolo,
                numero_protocolo: protocolData.numero_protocolo,
                nome_cliente: protocolData.nome_cliente,
                documento: protocolData.documento_representante || document,
                cod_farmacia: codFarmacia,
                nome_operador: '',
                itens_troca: productData.map(product => ({
                    cod_item: product.cod_item,
                    nome_item: product.nome || product.nome_item,
                    tipo_dispositivo: product.tipo_dispositivo || product.tipo_dispositivo,
                    imagem_item: product.url_imagem_caixa || product.imagem_item,
                    numero_serie: formValues[`${product.cod_item}-serie`] || '',
                    lote: formValues[`${product.cod_item}-lote`] || '',
                    validade: formValues[`${product.cod_item}-validade`] || ''
                }))
            };
            localStorage.setItem('jsonOutput', JSON.stringify(jsonOutput));
            navigate('/resumo');
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleCancelar = () => {
        localStorage.removeItem('jsonOutput');
        navigate('/informacoes');
    };

    return (
        <ProtocoloContainer>
            <div className='page-title'>
                <div className='title'>
                    <h2>Número de caso de intercambio:</h2>
                    <h2>{protocolNumber}</h2>
                </div>
                <img src={QuestionIcon} onClick={openModal} alt="question-icon" />
            </div>
            <br />
            <div className='separator'>
                <h3>Dispositivos disponibles para intercambio</h3>
            </div>
            <br />

            {productData.map(product => (
                <div className='product-card' key={product.cod_item}>
                    <div className='product-div1'>
                        <div>
                            <img className='imagem-div1' src={product.imagem_item || product.url_imagem_caixa} alt={product.nome} />
                        </div>
                        <div>
                            <span>{product.nome || product.nome_item}</span>
                        </div>
                    </div>
                    <div>
                        {visibleFields[product.cod_item] ? (
                            <div className='hidden-fields'>
                                {product.tipo_dispositivo !== 'strip' && (
                                    <div className='hidden-field'>
                                        <strong>Número de serie</strong>
                                        <input
                                            className={`form-control ${serialValidationErrors[product.cod_item] ? 'error' : ''}`}
                                            type='text'
                                            maxLength={product.tipo_dispositivo === 'sensor' ? 11 : product.tipo_dispositivo === 'leitor' ? 13 : 20}
                                            value={formValues[`${product.cod_item}-serie`] || ''}
                                            onChange={(e) => handleInputChange(e, product.cod_item, 'serie')}
                                        />
                                        {serialValidationErrors[product.cod_item] && (
                                            <div className="error-message">{serialValidationErrors[product.cod_item]}</div>
                                        )}
                                    </div>
                                )}
                                <div className='hidden-field'>
                                    <strong>Lote</strong>
                                    <input
                                        className={`form-control ${serialValidationErrors[`${product.cod_item}-lote`] ? 'error' : ''}`}
                                        type='text'
                                        maxLength={product.tipo_dispositivo === 'sensor' ? 9 : 7}
                                        value={formValues[`${product.cod_item}-lote`] || ''}
                                        onChange={(e) => handleInputChange(e, product.cod_item, 'lote')}
                                    />
                                    {serialValidationErrors[`${product.cod_item}-lote`] && (
                                        <div className="error-message-bigger">{serialValidationErrors[`${product.cod_item}-lote`]}</div>
                                    )}
                                </div>
                                {product.tipo_dispositivo !== 'leitor' && (
                                    <div className='hidden-field'>
                                        <strong>Vencimiento</strong>
                                        <input
                                            className={`form-control ${serialValidationErrors[`${product.cod_item}-validade`] ? 'error' : ''}`}
                                            type='date'
                                            placeholder='DD/MM/YYYY'
                                            value={formValues[`${product.cod_item}-validade`] || ''}
                                            onChange={(e) => handleInputChange(e, product.cod_item, 'validade')}
                                        />
                                        {serialValidationErrors[`${product.cod_item}-validade`] && (
                                            <div className="error-message">{serialValidationErrors[`${product.cod_item}-validade`]}</div>
                                        )}
                                    </div>
                                )}
                                <div className='x-button-div'>
                                    <button className='button-terciary' onClick={() => handleCancel(product.cod_item)}>X</button>
                                </div>
                            </div>
                        ) : (
                            <button className='button' onClick={() => handleFillCadastro(product.cod_item)}>Llenar formulario</button>
                        )}
                    </div>
                </div>
            ))}
            <div className='button-div'>
                <button className='button-secondary' onClick={handleCancelar}>Cancelar</button>
                <button
                    className={`button ${!isFormValid || Object.values(serialValidationErrors).some(err => err) ? 'disabled' : ''}`}
                    onClick={handleAvancar}
                    disabled={!isFormValid || Object.values(serialValidationErrors).some(err => err)}
                >
                    Avanzar
                </button>
            </div>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Instrucciones para el intercambio de equipos</h4>
                        </div>
                        <br />
                        <div className="modal-body">
                            <ol>
                                <li>Cada equipo tiene un número de serie único.</li>
                                <li>Cada campo llenado corresponde a un equipo menos en el saldo total de intercambio del cliente.</li>
                                <li>Llene todos los campos si tiene los equipos disponibles en stock.</li>
                                <li>Si no llena todos los campos por falta de producto en stock, el cliente podrá realizar el retiro en otro momento en cualquier tienda autorizada que tenga el equipo disponible.</li>
                            </ol>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary ok_btn" onClick={closeModal}>Entendido</button>
                        </div>
                    </div>
                </div>
            )}
        </ProtocoloContainer>
    );
}
